<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 1116.907 904.117"
  >
    <path
      fill="currentColor"
      d="M23.554 587.447a1456.658 1456.658 0 0 0 1087.324-382.87 624.9 624.9 0 0 1-396.365 539.085A631.562 631.562 0 0 1 23.554 587.448"
    />
  </svg>
</template>
